/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql, Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { MdBuild, MdChevronRight, MdGavel, MdLocalAtm, MdPictureAsPdf, MdBookmarkBorder, } from "react-icons/md"

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero, CallToAction } from '../../../components/elements'

const Index = ({ data }) => {

  const page = data.prismic.information

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title={RichText.asText(page.title)} />
      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            {/* Service Issues */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'blue.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>Service Issues</h2><MdBuild sx={{ fill: 'blue.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'blue.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>When things go wrong, it is important to get them fixed as soon as possible, especially when it comes to water or sewer issues. We have provided information on how to handle water and sewer problems that may arise, and how to prevent them.</p>
                <Link to="customer-service/information/service-issues" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>

            {/* Policies & Procedures */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'green.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>Policies & Procedures</h2><MdGavel sx={{ fill: 'green.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'green.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>Learn about PUB's water and sewer policies for yourself. You can download information on water use and sewer use ordinances, and state and government rules and regulations for line construction.</p>
                <Link to="customer-service/information/policies-procedures" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>

            {/* Rates & Tariffs */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'yellow.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>Rates & Tariffs</h2><MdLocalAtm sx={{ fill: 'yellow.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'yellow.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>We want you to be informed of the water and sewer rates, and prices associated with you services so there are no surprises on your monthly bills. This is just one of the ways PUB empowers and puts its customers first.</p>
                <Link to="customer-service/information/rates-tariffs" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>

            {/* Reports & Ordinances */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'orange.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>Reports & Ordinances</h2><MdPictureAsPdf sx={{ fill: 'orange.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'orange.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>You can dig deeper into the documents that make PUB what it is. To stay up on the latest at PUB, you can download annual reports, information on budgeting, agendas and meetings, and so much more.</p>
                <Link to="customer-service/information/reports-ordinances" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>

            {/* Resources */}
            <Box width={['full', 4 / 5, 3 / 4]} color="white">
              <Box sx={{ bg: 'red.5', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 sx={{ margin: 0 }}>Resources</h2><MdBookmarkBorder sx={{ fill: 'red.6', fontSize: '3em' }} />
              </Box>
              <Box sx={{
                bg: 'red.6', p: 4
              }}>
                <p sx={{ marginBottom: 0 }}>Not only is PUB dedicated to providing exceptional water and sewer services to its customers, we are also advocates for preserving our wonderful city and state. Here you will find environmental resources for Parkersburg and West Virginia.</p>
                <Link to="customer-service/information/resources" sx={{ color: 'white', textDecoration: 'none', display: 'block', mt: 2, display: 'flex', alignItems: 'center', fontWeight: 'bold', "&:hover": { color: 'white', textDecoration: 'underline' } }}>Learn More <MdChevronRight /></Link>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Section>

      <CallToAction />

    </Layout>
  )
}

export default Index

export const query = graphql`
query InformationQuery {
  prismic {
    information(lang: "en-us", uid: "information") {
      title
      content
      meta_title
      meta_description
      _linkType
    }
  }
}
`